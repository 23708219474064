
// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);

// Variables
@import "variables";

// Bootstrap
// @import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

.info-forms{
    form{
        &.details{
            // input[type="text"] {
            //     margin: 0 0 36px 0;
            // }
            span.error {
                margin: -15px 0 15px 0 !important;
            }
            .row {
                float: left;
                position: relative;
                width: auto;
                @media (max-width: 543px){
                    margin: 0px;
                    width: 100%;
                }
                a.edit {
                    position: absolute;
                    top: 20px;
                    right: 15px;
                    background: #bbb;
                    color: #fff;
                    padding: 4px 6px;
                    border-radius: 50px;
                    width: 24px;
                    height: 24px;
                    cursor: pointer;
                    &:hover{
                        background: linear-gradient(to right, #fd3542, #fd7647);
                        color: #fff;
                    }
                }
            }
            .sign-up-form{
                .row {
                    // width: auto;
                }
            }
            .full-width {
                width: 100%;
                float: left;
            }
            .checkbox{
                li{
                    input {
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: 100%;
                        opacity: 0;
                        z-index: 9999999;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}

section{
    &.dashboard-bottom {
        padding: 0 0 30px 0 !important;
    }
}

span.error, span.form-control-feedback {
    color: red !important;
    font-size: 13px !important;
    margin: 0px  0 !important;
    display:block;
    text-transform: none !important;
}

.step-forms{
    .left-section{
        .upload-photo{
            div.upload {
                background: #fff;
                border: 2px dashed #e4e3e3;
                padding: 0;
                text-align: center;
                color: #000;
                font-size: 13px;
                display: block;
                min-height: inherit;
                margin: 0 0 20px 0;
                position: relative;
                button.profileimage {
                    background: linear-gradient(to right, #fd3542, #fd7647);
                    color: #fff;
                    padding: 1px 4px;
                    font-size: 14px;
                    border-radius: 50px;
                    width: 25px;
                    height: 25px;
                    display: inline-block;
                    text-align: center;
                    position: absolute;
                    right: -8px;
                    top: -7px;
                    z-index: 999;
                }
                img {
                    max-height: inherit;
                }
                input.image-profile {
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 100%;
                    width: 100%;
                    cursor: pointer;
                    opacity: 0;
                    z-index: 99;
                }
                a.edit-icon {
                    background: linear-gradient(to right, #fd3542, #fd7647);
                    color: #fff;
                    padding: 9px 10px;
                    font-size: 17px;
                    border-radius: 50px;
                    width: 35px;
                    height: 35px;
                    display: inline-block;
                    text-align: center;
                    position: absolute;
                    right: -10px;
                    bottom: -4px;
                    z-index: 999;
                }
            }
        }
        .uploaded-images{
            a.upload{
              position: relative;
              input[type="file"] {
                  position: absolute;
                  left: 0;
                  top: 0;
                  width: 100%;
                  height: 100%;
                  z-index: 99;
                  opacity: 0;
                  cursor: pointer;
              }
            }
            ul.gallery {
                display: block;
                float: left;
                width: 100%;
                margin: 0 -1px;
                li {
                    width: 32%;
                    display: inline-block;
                    vertical-align: top;
                    margin: 0 0 1% 1%;
                    position: relative;
                    float: left;
                    button {
                        position: absolute;
                        right: 0;
                        top: 0;
                        background: rgba(0,0,0,0.5);
                        color: #fff;
                    }
                }
            }
        }
        ul.videoshow{
            li {
                position: relative;
                float: left;
                width: 100%;
                button {
                    position: absolute;
                    right: 0;
                    top: 0;
                    background: rgba(0, 0, 0, 0.5);
                    color: #fff;
                }
                iframe {
                    border: none;
                }
            }
        }
    }
    .right-section{
        .main-info{
            .inner-content{
                select{
                width: 100%;
                border: 1px solid #d2d1d1;
                border-radius: 3px;
                padding: 4px 14px;
                margin: 0 0 30px 0;
                height: 36px;
                background: #f7f7f8 url('http://phplaravel-107195-304625.cloudwaysapps.com/storage/THPHMsNDuw6U2SomE0yo9FqKAqN3uoqnePsqVSp2.png');
                appearance: none;
                -moz-appearance: none;
                -webkit-appearance: none;
                background-size: 14px;
                background-repeat: no-repeat;
                background-position: 98% 50%;
                    @media (max-width: 767px){
                        height: 34px !important;
				    	padding: 6px 10px !important;
                    }
                }
            }
        }
        a.edit {
            margin: 0 0px 0 14px;
        }
        span.saving {
            width: 21px;
            position: relative;
            top: -7px;
            left: 6px;
            img {
                width: 20px;
            }
        }
    }
}

input[type=range] {
  -webkit-appearance: none;
  margin: 18px 0;
  display: inline-block;
}
input[type=range]:focus {
  outline: none;
}
input[type=range]::-webkit-slider-runnable-track {
    display: inline-block;
    max-width: 220px;
    width: 100%;
    margin: 0 9px 0 0;
    background: linear-gradient(to right, #fd3542, #fd7647);
    border: none;
    border-radius: 23px;
    height: 6px;
    cursor: pointer;
}

input[type=range]::-webkit-slider-thumb {
    background: linear-gradient(to right, #fd3542, #fd7647) !important;
    -webkit-appearance: none;
    border: 5px solid #fff !important;
    border-radius: 40px !important;
    width: 1.2em !important;
    height: 1.2em !important;
    display: block !important;
    z-index: 2;
    margin: -5px 0 0 0;
}

input[type=range]:focus::-webkit-slider-runnable-track {
  background: linear-gradient(to right, #fd3542, #fd7647);
}
input[type=range]::-moz-range-track {
    display: inline-block;
    max-width: 220px;
    width: 100%;
    margin: 0 9px 0 0;
    background: linear-gradient(to right, #fd3542, #fd7647);
    border: none;
    border-radius: 23px;
    height: 6px;
    cursor: pointer;
}
input[type=range]::-moz-range-thumb {
    background: linear-gradient(to right, #fd3542, #fd7647) !important;
    border: 5px solid #fff !important;
    border-radius: 40px !important;
    width: 1.2em !important;
    height: 1.2em !important;
    display: block !important;
    z-index: 2;
    margin: -5px 0 0 0;
}
input[type=range]::-ms-track {
    display: inline-block;
    max-width: 220px;
    width: 100%;
    margin: 0 9px 0 0;
    background: linear-gradient(to right, #fd3542, #fd7647);
    border: none;
    border-radius: 23px;
    height: 6px;
    cursor: pointer;
}
input[type=range]::-ms-fill-lower {
  background: #2a6495;
  border: 0.2px solid #010101;
  border-radius: 2.6px;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
}
input[type=range]::-ms-fill-upper {
  background: #3071a9;
  border: 0.2px solid #010101;
  border-radius: 2.6px;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
}
input[type=range]::-ms-thumb {
    background: linear-gradient(to right, #fd3542, #fd7647) !important;
    border: 5px solid #fff !important;
    border-radius: 40px !important;
    width: 1.2em !important;
    height: 1.2em !important;
    display: block !important;
    z-index: 2;
    margin: -5px 0 0 0;
}
input[type=range]:focus::-ms-fill-lower {
  background: #3071a9;
}
input[type=range]:focus::-ms-fill-upper {
  background: #367ebd;
}